import formStyles from "components/Form/form.module.css";

const Currencies = ({ currencies = [], selected, OnCurrency }) => (
  <div className={formStyles.group}>
    <label htmlFor="currency">Валюта</label>
    <select
      required
      id="currency"
      value={selected?.code || ""}
      onChange={(e) =>
        OnCurrency(
          currencies.find((currency) => currency.code === e.target.value)
        )
      }
    >
      {!selected && (
        <option value="" disabled>
          Выберите валюту
        </option>
      )}

      {currencies &&
        currencies.length > 0 &&
        currencies.map((currency) => (
          <option key={currency.code} value={currency.code}>
            {currency.code}
          </option>
        ))}
    </select>
  </div>
);

export default Currencies;
