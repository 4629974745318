import formStyles from "components/Form/form.module.css";

const Partners = ({ partners = [], selected, fetching = true, OnPartner }) => {
  const handleChange = (e) => {
    const partner = partners.find(
      (p) => String(p.partner_id) === e.target.value
    );
    OnPartner(partner);
  };
  return (
    <div className={formStyles.group}>
      <label htmlFor="partner">Партнер перевода</label>
      <select
        id="partner"
        value={selected?.partner_id || ""}
        onChange={handleChange}
        required
      >
        {!selected && (
          <option value="" disabled>
            {fetching === true ? "Загружаем список.." : "Выберите партнера"}
          </option>
        )}

        {partners &&
          partners.length > 0 &&
          partners.map((partner) => (
            <option key={partner.partner_id} value={partner.partner_id}>
              {partner.name}
            </option>
          ))}
      </select>
    </div>
  );
};

export default Partners;
