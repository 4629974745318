import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import formStyles from "components/Form/form.module.css";
import { useRia } from "./RiaContext";
import transferAnimation from "./transfer-animation.json";

import Formdata from "./Formdata/Formdata";
import Alert from "components/Alert/Alert";
const Preloader = () => {
  const animationStyles = {
    display: "flex",
    justifyContent: "center",
    padding: "5rem",
  };
  return (
    <div style={animationStyles}>
      <Lottie
        animationData={transferAnimation}
        style={{
          width: "100px",
          height: "100px",
          opacity: 0.5,
        }}
      />
    </div>
  );
};

const StepFour = ({ formData, onChange }) => {
  const {
    checkFormData,
    resetRequest,
    fetchingCheckRequest,
    checkRequestResponse,
    checkRequestError,
  } = useRia();

  const [checkboxState, setCheckboxState] = useState({
    isDataVerified: false,
    isFeeAccepted: false,
  });
  const handleCheckboxChange = (updatedState) => {
    setCheckboxState(updatedState);
  };

  useEffect(() => {
    // console.log("StepFour", token, formData);
    if (formData && !checkRequestResponse && !checkRequestError) {
      checkFormData(formData);
    }
    if (checkRequestError) onChange("step4")("error");
    if (checkRequestResponse) onChange("step4")("response");
  }, [formData, checkRequestResponse, checkRequestError]);

  const handleRepeatClick = () => {
    resetRequest();
    onChange("step4")("repeat");
  };

  const isContinue =
    checkboxState.isDataVerified && checkboxState.isFeeAccepted;
  return (
    <div>
      <div className={formStyles.header}>
        <h1>Перевод в другую страну</h1>
        <h2 className={formStyles.stepTitle}>
          {(fetchingCheckRequest || checkRequestError) &&
            "Шаг 4: проверяем данные на перевод"}
          {!fetchingCheckRequest &&
            !checkRequestError &&
            checkRequestResponse &&
            "Шаг 4: проверьте данные перед отправкой перевода"}
        </h2>
      </div>

      {checkRequestError && (
        <div style={{ padding: "1rem 0" }}>
          <Alert
            type="danger"
            title={checkRequestError.title}
            message={checkRequestError.message}
          />
        </div>
      )}

      {!checkRequestError && checkRequestResponse && (
        <Formdata
          data={formData}
          checkData={checkRequestResponse}
          onCheckboxChange={handleCheckboxChange}
        />
      )}
      {/* <div style={{ padding: "1rem 0" }}>{JSON.stringify(checkboxState)}</div> */}

      {fetchingCheckRequest && <Preloader />}

      {/* Go to step 1 */}
      {checkRequestError && (
        <div className={formStyles.footer}>
          <button
            type="button"
            className={formStyles.submitButton}
            onClick={handleRepeatClick}
          >
            Повторить
          </button>
        </div>
      )}

      {/* Go to POST */}
      {!checkRequestError && checkRequestResponse && (
        <div className={formStyles.footer}>
          <button
            type="button"
            className={formStyles.submitButton}
            disabled={!isContinue}
          >
            Отправить перевод
          </button>
        </div>
      )}
    </div>
  );
};

export default StepFour;
