import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "AuthContext";
import { useUser } from "UserContext";
import { useRia } from "./RiaContext";
// import { transferToWallet } from "api";
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import formStyles from "components/Form/form.module.css";
import Alert from "components/Alert/Alert";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";

const initialformData = {
  country: null,
  amount: 100,
  partner: null,
  method: "1", // CashPickup
  currency: null,
  // Данные на получателя
  beneficiaryFirstName: "",
  beneficiaryLastName: "",
  beneficiaryDateOfBirth: "",
  // Паспортные данные
  beneficiaryIDNumber: "",
  beneficiaryIDType: "Passport",
  // Адрес
  beneficiaryAddress: "",
  beneficiaryCity: "",
  beneficiaryState: getState(),
  beneficiaryZipCode: "",
  beneficiaryCountry: "",
};

const initialState = {
  step: 1,
  error: null,
  success: false,
  isTransferred: false,
  inProgress: false,
  beneficiaryAgent: null,
};

const validateForm = (formData, state, wallet) => {
  const errors = {};
  if (state.step === 1) {
    if (!formData.amount) errors.amount = "Введите сумму перевода";
    if (!formData.country) errors.country = "Выберите страну";
    if (formData.country && !formData.partner)
      errors.partner = "Выберите партнера";
    if (formData.partner && !formData.method)
      errors.method = "Выберите способ доставки";
    if (formData.method && !formData.currency)
      errors.currency = "Выберите валюту";
  }

  return errors;
};

function getState() {
  return Math.floor(Math.random() * (85 - 20 + 1)) + 20;
}

const ToCountryPage = () => {
  const { isAuthenticated } = useAuth();
  const { wallets, setWallets, user } = useUser();
  const { dropData } = useRia();

  const [formData, setFormData] = useState(initialformData);
  const [state, setState] = useState(initialState);

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    return () => {
      if (state.isTransferred) {
        setWallets([]);
      }
      setState(initialState);
      setFormData(initialformData);
      dropData();
    };
  }, [state.isTransferred, setWallets]);

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (user?.agent.status === 1) {
    return <Navigate to="/wallets" replace />;
  }

  const handleInputChange = (field) => (value) => {
    // console.group("State change", field, value);
    if (field === "step4") {
      if (value !== "repeat")
        setState((prev) => ({ ...prev, inProgress: false }));
      else setState((prev) => ({ ...prev, inProgress: false, step: 1 }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Очищаем ошибку поля при изменении значения
    if (formErrors[field]) {
      setFormErrors((prev) => ({
        ...prev,
        [field]: null,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validateForm(formData, state, wallets[0]);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setState((prev) => ({
        ...prev,
        error: { title: "Ошибка", message: "Заполните все обязательные поля" },
      }));
      return;
    }

    try {
      // Логика обработки формы
      if (state.step === 1) {
        console.log(formData);
        setState((prev) => ({ ...prev, step: 2 }));
      }
      if (state.step === 2) {
        console.log(formData);
        setState((prev) => ({ ...prev, step: 3 }));
      }

      if (state.step === 3) {
        console.log(formData);
        setState((prev) => ({
          ...prev,
          step: 4,
          inProgress: true,
          noFooter: true,
        }));
      }
    } catch (error) {
      setState((prev) => ({
        ...prev,
        error: {
          title: "Ошибка перевода",
          message: error.message || "Произошла ошибка при переводе средств",
        },
        inProgress: false,
      }));
    }
  };

  const renderStepContent = () => {
    switch (state.step) {
      case 1:
        return (
          <StepOne
            wallets={wallets}
            formData={formData}
            onChange={handleInputChange}
            errors={formErrors}
          />
        );
      case 2:
        return (
          <StepTwo
            formData={formData}
            onChange={handleInputChange}
            errors={formErrors}
          />
        );
      case 3:
        return (
          <StepThree
            formData={formData}
            onChange={handleInputChange}
            errors={formErrors}
          />
        );
      case 4:
        return (
          <StepFour
            formData={formData}
            onChange={handleInputChange}
            errors={formErrors}
          />
        );

      // Здесь могут быть другие шаги
      default:
        return null;
    }
  };

  return (
    <InnerPageLayout title="Международный перевод" backURL="/wallets">
      <div className={formStyles.wrapper}>
        <form
          className={formStyles.form}
          onSubmit={handleSubmit}
          aria-disabled={state.inProgress}
        >
          {renderStepContent()}
        </form>
      </div>
    </InnerPageLayout>
  );
};

export default ToCountryPage;
