import React, { useState } from "react";
import styles from "./Formdata.module.css";

const mapRequestBody = (form) => ({
  amount: form.amount * 100,
  currency: form.currency.code,
  payment_country: form.country.code,
  beneficiary: {
    personal_information: {
      bene_first_name: form.beneficiaryFirstName,
      bene_last_name: form.beneficiaryLastName,
      bene_date_of_birth: form.beneficiaryDateOfBirth,
      bene_nationality: form.beneficiaryCountry.code,
      bene_country_of_birth: form.beneficiaryCountry.code,
    },
    residence: {
      bene_address: form.beneficiaryAddress,
      bene_city: form.beneficiaryCity,
      bene_state: form.beneficiaryState,
      bene_zip_code: form.beneficiaryZipCode,
      bene_country: form.beneficiaryCountry.code,
    },
    identity_document: {
      bene_id_no: form.beneficiaryIDNumber,
      bene_id_type: "Passport",
    },
  },
  payout_partner: {
    paying_corresp_id: form.partner.partner_id,
  },
});

function formatDate(dateString) {
  // Массив месяцев на русском языке
  const months = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];

  // Разбиваем строку на год, месяц и день
  const year = dateString.slice(0, 4);
  const month = parseInt(dateString.slice(4, 6), 10) - 1; // Индексы месяцев начинаются с 0
  const day = parseInt(dateString.slice(6, 8), 10);

  // Форматируем строку
  return `${day} ${months[month]} ${year}г`;
}

const Formdata = ({ data, onCheckboxChange }) => {
  const [checkboxState, setCheckboxState] = useState({
    isDataVerified: false,
    isFeeAccepted: false,
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    // Обновляем локальное состояние
    const updatedState = {
      ...checkboxState,
      [name]: checked,
    };
    setCheckboxState(updatedState);

    // Пробрасываем новое состояние в родительский компонент
    if (onCheckboxChange) {
      onCheckboxChange(updatedState);
    }
  };

  return (
    <>
      <div className={styles.item}>
        <label>Страна перевода</label>
        <span>{data.country.name}</span>
      </div>

      <div className={styles.item}>
        <label>Партнер</label>
        <span>{data.partner.name}</span>
      </div>

      <div className={styles.item}>
        <label>Получатель</label>
        <span>
          {data.beneficiaryFirstName} {data.beneficiaryLastName}
        </span>
      </div>

      <div className={styles.item}>
        <label>Дата рождения получателя</label>
        <span>{formatDate(data.beneficiaryDateOfBirth)}</span>
      </div>

      <div className={styles.item}>
        <label>Номер паспорта получателя</label>
        <span>{data.beneficiaryIDNumber}</span>
      </div>

      <div className={styles.item}>
        <label>Страна регистрации получателя</label>
        <span>{data.beneficiaryCountry.name}</span>
      </div>

      <div className={styles.item}>
        <label>Почтовый индекс</label>
        <span>{data.beneficiaryZipCode}</span>
      </div>

      <div className={styles.item}>
        <label>Адрес</label>
        <span>
          {data.beneficiaryCity}, {data.beneficiaryAddress}
        </span>
      </div>

      <div className={styles.item}>
        <label>Сумма перевода</label>
        <span>{data.amount} ₸</span>
      </div>

      <div className={styles.item}>
        <label>Сумма к получению</label>
        <span>??? {data.currency.code}</span>
      </div>

      <div className={styles.checkboxes}>
        <label>
          <input
            type="checkbox"
            name="isDataVerified"
            checked={checkboxState.isDataVerified}
            onChange={handleCheckboxChange}
          />{" "}
          Данные верны. Я проверил(а)
        </label>

        <label>
          <input
            type="checkbox"
            name="isFeeAccepted"
            checked={checkboxState.isFeeAccepted}
            onChange={handleCheckboxChange}
          />{" "}
          Соглашаюсь с комиссией за перевод
        </label>
      </div>
    </>
  );
};

export default Formdata;
