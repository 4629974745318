import React, { useEffect } from "react";
import { useRia } from "./RiaContext";
import SelectCountry from "./SelectCountry";
import SelectPartner from "./SelectPartner";
import SelectMethod from "./SelectMethod";
import SelectCurrency from "./SelectCurrency";
import formStyles from "components/Form/form.module.css";

import Alert from "components/Alert/Alert";

const StepOne = ({ wallets = [], formData, onChange }) => {
  const { amount, country, partner, method, currency } = formData;
  const {
    countries,
    fetchingCountries,
    countriesError,
    fetchPartners,
    partners,
    fetchingPartners,
    partnersError,
  } = useRia();

  const handleCountryChange = (selectedCountry) => {
    onChange("amount")(100);
    onChange("partner")(null);
    onChange("currency")(null);
    onChange("country")(selectedCountry);
    if (selectedCountry) {
      fetchPartners(selectedCountry.code);
    }
  };

  const handlePartnerChange = (selectedPartner) => {
    onChange("amount")(100);
    onChange("currency")(null);
    onChange("partner")(selectedPartner);
  };

  const getPartnerById = (partnerId) => {
    return partners.find((partner) => String(partner.partner_id) === partnerId);
  };

  const isBalanceError =
    wallets && wallets.length > 0 ? amount > wallets[0]?.amount / 100 : false;

  return (
    <div className={formStyles.step}>
      <div className={formStyles.header}>
        <h1>Перевод в другую страну</h1>
        <h2 className={formStyles.stepTitle}>
          Шаг 1: куда и каким способом будет сделан перевод
        </h2>
      </div>

      {countriesError && (
        <div style={{ paddingBottom: "1.5rem" }}>
          <Alert
            type="danger"
            title="Не удалось загрузить список стран для перевода"
            message="Попробуйте обновить страницу. Если проблема повторится, то обратитесь в нашу тех поддержку."
          />
        </div>
      )}

      <SelectCountry
        countries={countries}
        selected={country}
        OnCountry={handleCountryChange}
        fetching={fetchingCountries}
      />

      {fetchingPartners && (
        <div style={{ padding: "1rem 0" }}>
          <Alert
            type="info"
            title="Загружаем данные"
            message="Получаем список партнёров.."
          />
        </div>
      )}

      {partnersError && (
        <div style={{ padding: "1rem 0" }}>
          <Alert
            type="danger"
            title="Нет партнеров для перевода"
            message="В выбранной вами стране нет партнеров, которым мы можем отправить перевод"
          />
        </div>
      )}

      {country && partners.length > 0 && (
        <SelectPartner
          partners={partners}
          selected={partner}
          OnPartner={handlePartnerChange}
          fetching={fetchingPartners}
        />
      )}

      {/* {partner && selectedPartner && (
        <SelectMethod
          methods={selectedPartner.delivery_methods}
          selected={method}
          OnMethod={onChange("method")}
          error={errors?.method}
        />
      )} */}

      {/* <div className={formStyles.group}>{JSON.stringify(formData.partner)}</div> */}
      {partner && (
        <SelectCurrency
          currencies={partner.delivery_methods[0].currencies}
          selected={currency}
          OnCurrency={onChange("currency")}
        />
      )}

      {currency && (
        <div className={formStyles.group} state={isBalanceError ? "error" : ""}>
          <label htmlFor="amount">Сумма перевода</label>
          <div className={formStyles.field}>
            <input
              type="number"
              id="amount"
              name="amount"
              value={amount}
              min={currency.minAmount}
              // {...(currency.maxAmount > 0 && { max: currency.maxAmount })}
              onChange={(e) => onChange("amount")(e.target.value)}
              placeholder="Введите сумму"
              required
            />
            <small>
              {isBalanceError ? "недостаточно средств на балансе" : "в тенге"}
              {/* {!currency.maxAmount || currency.maxAmount === 0
                ? "Ограничений по сумме нет"
                : `Не более ${currency.maxAmount}${currency.code}`} */}
            </small>
          </div>
        </div>
      )}

      <div className={formStyles.footer}>
        <button
          type="submit"
          className={formStyles.submitButton}
          disabled={isBalanceError || partnersError}
        >
          Продолжить
        </button>
      </div>
    </div>
  );
};

export default StepOne;
