import React, { useState, useEffect, useContext } from "react";
import { getCountries, getPartners, checkRequest } from "api/ria";
import { useAuth } from "AuthContext";

const mapRequestBody = (form) => ({
  amount: form.amount * 100,
  currency: form.currency.code,
  payment_country: form.country.code,
  beneficiary: {
    personal_information: {
      bene_first_name: form.beneficiaryFirstName,
      bene_last_name: form.beneficiaryLastName,
      bene_date_of_birth: form.beneficiaryDateOfBirth,
      bene_nationality: form.beneficiaryCountry.code,
      bene_country_of_birth: form.beneficiaryCountry.code,
    },
    residence: {
      bene_address: form.beneficiaryAddress,
      bene_city: form.beneficiaryCity,
      bene_state: form.beneficiaryState,
      bene_zip_code: form.beneficiaryZipCode,
      bene_country: form.beneficiaryCountry.code,
    },
    identity_document: {
      bene_id_no: form.beneficiaryIDNumber,
      bene_id_type: "Passport",
    },
  },
  payout_partner: {
    paying_corresp_id: form.partner.partner_id,
  },
});

const RiaContext = React.createContext();
export const RiaProvider = ({ children }) => {
  const { token } = useAuth();
  const [countries, setCountries] = useState(null);
  const [fetchingCountries, setFetchingCountries] = useState(false);
  const [countriesError, setCountriesError] = useState(false);

  const [partners, setPartners] = useState(null);
  const [fetchingPartners, setFetchingPartners] = useState(false);
  const [partnersError, setPartnersError] = useState(false);

  const [fetchingCheckRequest, setFetchingCheckRequest] = useState(false);
  const [checkRequestResponse, setCheckRequestResponse] = useState(false);
  const [checkRequestError, setCheckRequestError] = useState(false);

  useEffect(() => {
    // if (!token) return;
    if (!countries) fetchCountries();
  }, [countries]);

  const fetchCountries = async () => {
    try {
      setFetchingCountries(true);
      const countriesData = await getCountries();
      if (countriesData && countriesData.length > 0) {
        setCountries(countriesData);
      } else {
        setCountriesError(true);
      }
      setFetchingCountries(false);
    } catch (e) {
      setCountriesError(true);
    } finally {
      setFetchingCountries(false);
    }
  };

  const fetchPartners = async (countryCode) => {
    try {
      setPartners([]);
      setPartnersError(false);
      setFetchingPartners(true);
      const partnersData = await getPartners(countryCode);
      if (partnersData && partnersData.length > 0) {
        setPartners(partnersData);
      } else {
        setPartnersError(true);
      }
      setFetchingPartners(false);
    } catch (e) {
      setPartnersError(true);
    } finally {
      setFetchingPartners(false);
    }
  };

  const checkFormData = async (form) => {
    try {
      setCheckRequestResponse(false);
      setCheckRequestError(false);
      setFetchingCheckRequest(true);

      const checkData = await checkRequest(token, mapRequestBody(form));
      if (checkData) setCheckRequestResponse(checkData);
      else
        setCheckRequestError({
          title: "Ошибка",
          errorMessage: "Нет данных о результате проверки",
        });
      setFetchingCheckRequest(false);
    } catch (e) {
      const {
        name = false,
        description = false,
        error = false,
        errorMessage = false,
      } = e.response.data;
      setCheckRequestError({
        title: name && name === "RIA_ERROR" ? "Ошибка от провайдера" : "Ошибка",
        message: error || description || errorMessage || "Unknown error",
      });
    } finally {
      setFetchingCheckRequest(false);
    }
  };

  const resetRequest = () => {
    setCheckRequestResponse(false);
    setCheckRequestError(false);
  };

  const dropData = () => {
    setCountries(null);
    setFetchingCountries(false);
    setCountriesError(false);

    setPartners(null);
    setFetchingPartners(false);
    setPartnersError(false);

    setFetchingCheckRequest(false);
    setCheckRequestResponse(false);
    setCheckRequestError(false);
  };

  return (
    <RiaContext.Provider
      value={{
        dropData,
        countries,
        fetchingCountries,
        countriesError,
        fetchPartners,
        partners,
        fetchingPartners,
        partnersError,
        checkFormData,
        resetRequest,
        fetchingCheckRequest,
        checkRequestResponse,
        checkRequestError,
      }}
    >
      {children}
    </RiaContext.Provider>
  );
};

export const useRia = () => useContext(RiaContext);
