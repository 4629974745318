import formStyles from "components/Form/form.module.css";

const Countries = ({
  countries = [],
  selected,
  fetching = true,
  OnCountry,
}) => (
  <div className={formStyles.group}>
    <label htmlFor="country">Страна</label>
    <select
      required
      id="country"
      value={selected?.code || ""}
      onChange={(e) =>
        OnCountry(countries.find((country) => country.code === e.target.value))
      }
    >
      {!selected && (
        <option value="" disabled>
          {fetching === true ? "Загружаем список.." : "Выберите страну"}
        </option>
      )}

      {countries &&
        countries.length > 0 &&
        countries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
    </select>
  </div>
);

export default Countries;
