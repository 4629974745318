import React from "react";
import SelectCountry from "./SelectCountry";
import formStyles from "components/Form/form.module.css";
import { useRia } from "./RiaContext";

const StepThree = ({ formData, onChange, errors }) => {
  const {
    beneficiaryAddress,
    beneficiaryCity,
    beneficiaryState,
    beneficiaryZipCode,
    beneficiaryCountry,
  } = formData;

  const { countries, fetchingCountries } = useRia();

  return (
    <div>
      <div className={formStyles.header}>
        <h1>Перевод в другую страну</h1>
        <h2 className={formStyles.stepTitle}>
          Шаг 3: адрес регистрации получателя
        </h2>
      </div>

      <SelectCountry
        countries={countries}
        selected={beneficiaryCountry}
        OnCountry={onChange("beneficiaryCountry")}
        fetching={fetchingCountries}
      />

      <div className={formStyles.group}>
        <label htmlFor="beneficiaryZipCode">Почтовый индекс</label>
        <input
          type="number"
          id="beneficiaryZipCode"
          name="beneficiaryZipCode"
          value={beneficiaryZipCode}
          onChange={(e) => onChange("beneficiaryZipCode")(e.target.value)}
          placeholder=""
          required
        />
      </div>

      {/* <div className={formStyles.group}>
        <label htmlFor="beneficiaryState">Штат, регион или провинция</label>
        <input
          type="number"
          id="beneficiaryState"
          name="beneficiaryState"
          value={beneficiaryState}
          onChange={(e) => onChange("beneficiaryState")(e.target.value)}
          placeholder=""
          required
        />
      </div> */}

      <div className={formStyles.group}>
        <label htmlFor="beneficiaryCity">Город</label>
        <input
          type="text"
          id="beneficiaryCity"
          name="beneficiaryCity"
          value={beneficiaryCity}
          onChange={(e) => onChange("beneficiaryCity")(e.target.value)}
          placeholder=""
          required
        />
      </div>

      <div className={formStyles.group}>
        <label htmlFor="beneficiaryAddress">Адрес</label>
        <input
          type="text"
          id="beneficiaryAddress"
          name="beneficiaryAddress"
          value={beneficiaryAddress}
          onChange={(e) => onChange("beneficiaryAddress")(e.target.value)}
          placeholder="Улица и номер дома"
          required
        />
      </div>

      <div className={formStyles.footer}>
        <button type="submit" className={formStyles.submitButton}>
          Продолжить
        </button>
      </div>
    </div>
  );
};

export default StepThree;
