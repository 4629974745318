import React from "react";
import formStyles from "components/Form/form.module.css";

const StepTwo = ({ formData, onChange, errors }) => {
  const {
    beneficiaryFirstName,
    beneficiaryLastName,
    beneficiaryDateOfBirth,
    beneficiaryIDNumber,
  } = formData;

  const handleDateChange = (e) => {
    const date = e.target.value;
    if (date) {
      const formatted = date.replaceAll("-", "");
      onChange("beneficiaryDateOfBirth")(formatted);
    }
  };

  return (
    <div>
      <div className={formStyles.header}>
        <h1>Перевод в другую страну</h1>
        <h2 className={formStyles.stepTitle}>
          Шаг 2: паспортные данные получателя
        </h2>
      </div>

      <div className={formStyles.group}>
        <label htmlFor="beneficiaryIDNumber">Номер паспорта</label>
        <input
          type="text"
          id="beneficiaryIDNumber"
          name="beneficiaryIDNumber"
          value={beneficiaryIDNumber}
          onChange={(e) => onChange("beneficiaryIDNumber")(e.target.value)}
          placeholder=""
          required
        />
      </div>

      <div className={formStyles.group}>
        <label htmlFor="beneficiaryLastName">Фамилия из паспорта</label>
        <input
          type="text"
          id="beneficiaryLastName"
          name="beneficiaryLastName"
          value={beneficiaryLastName}
          onChange={(e) => onChange("beneficiaryLastName")(e.target.value)}
          placeholder="Smith"
          required
        />
      </div>

      <div className={formStyles.group}>
        <label htmlFor="beneficiaryFirstName">Имя из паспорта</label>
        <input
          type="text"
          id="beneficiaryFirstName"
          name="beneficiaryFirstName"
          value={beneficiaryFirstName}
          onChange={(e) => onChange("beneficiaryFirstName")(e.target.value)}
          placeholder="John"
          required
        />
      </div>

      <div className={formStyles.group}>
        <label htmlFor="beneficiaryDateOfBirth">
          Дата рождения из паспорта
        </label>
        <input
          type="date"
          id="beneficiaryDateOfBirth"
          name="beneficiaryDateOfBirth"
          onChange={handleDateChange}
          required
        />
      </div>

      <div className={formStyles.footer}>
        <button type="submit" className={formStyles.submitButton}>
          Продолжить
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
